import { isMobileRes } from './Utils';

class ParallaxMouseClass {
  #items = null;
  #elements;
  #isMobileRes = false;
  #initOnDocumentReady = false;
  #moving = false;

  constructor() {
    if (document.readyState === 'loading') {
      document.addEventListener(
        'DOMContentLoaded',
        this.#onDocumentReady.bind(this)
      );
    } else {
      this.#onDocumentReady();
    }
  }

  #onDocumentReady() {
    if (this.#initOnDocumentReady) {
      this.#init();
    }
  }

  init() {
    if (document.readyState === 'loading') {
      this.#initOnDocumentReady = true;
    } else {
      this.#init();
    }
  }

  #init() {
    this.#elements = document.querySelectorAll('.parallax');
    this.#isMobileRes = isMobileRes();
    this.#reset();

    if (!this.#isMobileRes) {
      window.addEventListener('mousemove', this.#onMouseMove.bind(this));
    }
  }

  #reset() {
    this.#items = [];

    const scrollY = Math.max(
      document.documentElement.scrollTop,
      document.body.scrollTop
    );

    Array.from(this.#elements).forEach((element) => {
      // element.style.transition = 'none';
      element.style.transform = 'none';

      const item = {
        element,
        y0: element.getBoundingClientRect().top + scrollY,
        offset: element.hasAttribute('data-parallax-offset')
          ? element.getAttribute('data-parallax-offset')
          : 0,
        velocity: element.hasAttribute('data-parallax-velocity')
          ? element.getAttribute('data-parallax-velocity')
          : 1,
        axis: element.hasAttribute('data-parallax-axis')
          ? element.getAttribute('data-parallax-axis')
          : 'y',
      };

      this.#items.push(item);
    });
  }

  #onMouseMove(e) {
    if (!this.#moving) {
      this.#moving = true;
      setTimeout(() => {
        this.#moving = false;
      }, 0);

      const diffX = e.clientX - window.innerWidth / 2;
      const offsetX = diffX / (window.innerWidth / 2); // 0 a 1

      const diffY = e.clientY - window.innerHeight / 2;
      const offsetY = diffY / (window.innerHeight / 2); // 0 a 1

      for (let i = 0; i < this.#elements.length; i++) {
        const maxOffsetX =
          this.#elements[i].getAttribute('data-parallax-offset-x') || 0;
        const maxOffsetY =
          this.#elements[i].getAttribute('data-parallax-offset-y') || 0;
        const directionX =
          this.#elements[i].getAttribute('data-parallax-direction-x') || -1;
        const directionY =
          this.#elements[i].getAttribute('data-parallax-direction-y') || -1;

        const x = directionX * offsetX * maxOffsetX;
        const y = directionY * offsetY * maxOffsetY;
        this.#elements[i].style.transform = `translate(${x}%, ${y}%)`;
      }
    }
  }

  #onResize() {
    this.#isMobileRes = isMobileRes();

    this.#reset();
  }
}

const ParallaxMouse = new ParallaxMouseClass();
Object.seal(ParallaxMouse);

export default ParallaxMouse;
