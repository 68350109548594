/* global Section */
import Wow from './utils/Wow';

class App {
  app = document.getElementById('app');

  #wow = Wow;

  #section;

  constructor() {
    this.#section = Section != null ? new Section() : null;

    this.#wow.init();
  }
}

export default App;
