export function isMobileRes() {
  return window.innerWidth <= 1024;
}

export function isMobileDevice() {
  return (
    typeof window.orientation !== 'undefined' || navigator.userAgent.indexOf('IEMobile') !== -1
  );
}

export function isiOS() {
  return /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
}

export function isFacebookInAppBrowser() {
  const ua = navigator.userAgent || navigator.vendor || window.opera;

  return ua.indexOf('FBAN') > -1 || ua.indexOf('FBAV') > -1;
}

export function gaSendEvent(action, category, label) {
  if (typeof window.ga !== 'undefined') {
    window.ga('send', 'event', {
      hitType: 'event',
      eventCategory: category,
      eventAction: action,
      eventLabel: label,
      transport: 'beacon',
    });
  }
}

export function gtagSendEvent(action, category, label) {
  if (typeof window.ga !== 'undefined') {
    window.gtag('event', action, {
      event_category: category,
      event_label: label,
    });
  }
}

export function copyToClipboard(text) {
  if (!navigator.clipboard) {
    const auxInput = document.body.appendChild(document.createElement('input'));
    auxInput.value = text;
    auxInput.focus();
    auxInput.select();
    document.execCommand('copy');
    auxInput.parentNode.removeChild(auxInput);

    return Promise.resolve();
  }
  return navigator.clipboard.writeText(text);
}

export function removeClassStartsWith(node, className) {
  [...node.classList].forEach((v) => {
    if (v.startsWith(className)) {
      node.classList.remove(v);
    }
  });
}

export function setLocalStorageWithExpire(key, value, days) {
  const now = new Date();
  const milliseconds = days * 24 * 60 * 60 * 1000;

  const item = {
    value,
    expire: now.getTime() + milliseconds,
  };
  localStorage.setItem(key, JSON.stringify(item));
}

export function getLocalStorageWithExpire(key) {
  const itemStr = localStorage.getItem(key);
  if (!itemStr) {
    return null;
  }

  const item = JSON.parse(itemStr);
  const now = new Date();

  if (now.getTime() > item.expire) {
    localStorage.removeItem(key);

    return null;
  }

  return item.value;
}
