import ParallaxMouse from '../../utils/ParallaxMouse';
import GaTrack from '../../utils/GaTrack';
import * as skrollTop from 'skrolltop';
import * as easings from '../../libs/easings.js';

class Home {
  parentEl = document.getElementById('home');
  #btnScrollDown;

  constructor() {
    this._sectionExperience = document.getElementById('section_experience');
    this.#btnScrollDown = this.parentEl.querySelector('.btn_scroll_down');

    ParallaxMouse.init();
    GaTrack.init();

    this.#btnScrollDown.addEventListener(
      'click',
      this.#onClickBtnScrollDown.bind(this)
    );
  }

  #onClickBtnScrollDown() {
    skrollTop.scrollTo({
      element: window,
      to: this._sectionExperience.offsetTop,
      easing: window.easings.easeInOutCubic,
      duration: 0,
    });
  }
}

export default Home;
