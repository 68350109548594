import { WOW } from 'wowjs';

class WowClass {
  #wow;

  constructor() {
    this.#wow = null;
  }

  init() {
    this.#wow = new WOW({
      boxClass: 'wow',
      offset: 0,
      mobile: false,
      live: true,
    });

    this.#wow.init();
  }

  sync() {
    this.#wow.sync();
  }
}

const Wow = new WowClass();

Object.seal(Wow);

export default Wow;
