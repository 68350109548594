import { gaSendEvent } from './Utils';

class GaTrackClass {
  #trackBtns;

  init() {
    this.#trackBtns = [...document.querySelectorAll('.ga_track')];
    this.#trackBtns.forEach((item) => {
      item.addEventListener('click', this.#onClickBtnTrack);
    });
  }

  #onClickBtnTrack(e) {
    const action = this.dataset.action;
    const category = this.dataset.category;
    const label = this.dataset.label;

    gaSendEvent(action, category, label);
  }
}

const GaTrack = new GaTrackClass();
Object.seal(GaTrack);

export default GaTrack;
